<template>
  <div style="padding: 10px">
    <div style="background: #fff; border-radius: 8px; padding: 20px">
      <div class="query-c">
        <Row>
           <Col span="12">
                <Input v-model="companyName" placeholder="公司名称" style="width: 300px" />
          </Col>
           <Col span="12">
                 <Button type="primary"  class="add" @click="findCompanyList"  icon="md-search">查询</Button>
                <Button type="primary"  class="search-bt" @click="add" icon="md-add">新增</Button>
          </Col>

        </Row>


    </div>
      <Table
        :max-height="maxHeight"
        border
        stripe
        :columns="columns"
        :data="companyList"
      ></Table>
      <br />
      <Page
            :total="total"
            @on-change="changePage"
            @on-page-size-change="changePageNum"
            show-sizer
            show-elevator />
    </div>

     <Modal
        v-model="updateModel"
        title="修改"
        @on-ok="updateOk"
        @on-cancel="updateCancel">
        <span>
          公司名称：
        </span>
        <Input v-model="updateVo.companyName" style="width: 300px" />
    </Modal>

    <Modal
        v-model="delModel"
        title="删除"
        @on-ok="delOk"
        @on-cancel="delCancel">
        <p>确定删除？</p>
    </Modal>

    <Modal
        v-model="addModel"
        title="新增"
        @on-ok="addOk"
        @on-cancel="addCancel">
         <span>
          公司名称：
        </span>
        <Input v-model="addVo.companyName" style="width: 300px" />
    </Modal>
  </div>
</template>

<script>
import { updateCompanyVo, delCompany, addCompany, findCompanyList } from '@/api'

let moment = require('moment')
 // require
export default {
  name: 'CompantManage',
  data() {
    return {
      maxHeight: 800,
      pageIndex: 1,
      pageSize: 10,
      pageNum: 10,
      total: 100,
      companyName: '',
      companyList: [],
      businessList: [],
      dataList: [],
      startTime: 0,
      endTime: new Date().getTime(),
      updateModel: false,
      updateVo: {
        companyName: '',
        cid: '',
      },
      delModel: false,
      delCid: '',
      addModel: false,
      addVo: {
        companyName: '',
      },
      columns: [
           {
              type: 'index',
              width: 60,
              align: 'center',
            },
        {
          title: '公司名称',
          key: 'companyName',
          resizable: true,
        },
        {
          title: '创建时间',
          key: 'createTime',
          resizable: true,
           render: (h, params) => h('div',
                moment(params.row.createTime).format('yyyy-MM-DD hh:mm')),
        },
        {
          title: '操作',
          key: 'action',
          width: 150,
          align: 'center',
          render: (h, params) => h('div', [
              h(
                'Button',
                {
                  props: {
                    type: 'primary',
                    size: 'small',
                  },
                  style: {
                    marginRight: '5px',
                  },
                  on: {
                    click: () => {
                      this.update(params)
                    },
                  },
                },
                '修改'
              ),
              h(
                'Button',
                {
                  props: {
                    type: 'error',
                    size: 'small',
                  },
                  on: {
                    click: () => {
                      this.remove(params.row)
                    },
                  },
                },
                '删除'
              ),
            ]),
        },
      ],
    }
  },
  mounted() {
    // 设置表格高度
    const t = this
    t.maxHeight = window.innerHeight - 240
    // this.findCzPriceList();
    // this.findCzList();
    // this.findThickList();
    this.findCompanyList()
    // this.findSalesBusinessList()
  },
created() {

  },
methods: {
      findCompanyList() {
            let data = 'pageSize=' + this.pageSize + '&pageIndex=' + this.pageIndex
            if (this.companyName.length > 0) {
              data = data + '&companyName=' + this.companyName
            }
            findCompanyList(data).then(res => {
                this.companyList = res.data.list
                this.total = res.data.count
            })
      },
      findCompanyVoList() {
          // eslint-disable-next-line no-undef
          findCompanyVoList().then(res => {
              this.companyList = res.data
          })
      },
          changePageNum(size) {
              this.pageSize = size
              this.findCompanyList()
            },
            changePage(p) {
              this.pageIndex = p
              this.findCompanyList()
            },
            dateHandleChange(date) {
                if (date[0] === '') {
                    this.startTime = 0
                } else {
                     this.startTime = moment(date[0], 'YYYY/MM/DD').valueOf()
                }
                if (date[1] === '') {
                    this.endTime = new Date().getTime()
                } else {
                    this.endTime = moment(date[1], 'YYYY/MM/DD').valueOf()
                }
            },
            formatDate(date) {
                console.log(date)
                    return moment(date).format('YYYY-MM-DD')
            },
            update(params) {
              // console.log(params)
              this.updateVo.companyName = params.row.companyName
              this.updateVo.cid = params.row.cid
              console.log(this.updateVo)
              this.updateModel = true
            },
            updateOk() {
               updateCompanyVo(this.updateVo).then(res => {
                   console.log(res.data)
                   this.findCompanyList()
                   this.$Message.success('操作成功')
                   this.updateModel = false
               })
            },
              updateCancel() {
              console.log('updateCancel')
            },
            remove(row) {
              console.log(row)
              this.delModel = true
              this.delCid = row.cid
            },
            delOk() {
                console.log(this.delCid)
                delCompany(this.delCid).then(res => {
                    this.$Message.success('操作成功')
                    this.findCompanyList()
                })
            },
            delCancel(row) {
              console.log(row)
            },
            add() {
                this.addModel = true
            },
            addOk() {
                  // eslint-disable-next-line no-unused-vars
                  addCompany(this.addVo).then(res => {
                     this.$Message.success('操作成功')
                     this.findCompanyList()
                     this.addVo = {}
                      this.addModel = false
                  })
                        },
            addCancel() {

                        },
  },
}
</script>

<style scoped>
.add{
    position: absolute;
    right: 40px;
}
.search-bt{
      position: absolute;
    right: 140px;
}
.query-c{
  height: 50px;
}
</style>